import React from "react";
import { Link } from "gatsby";
const Index = () => {
  return (
    <div>
      <div>404 Error - Page Not Found</div>
      <Link to="/">Return to home page</Link>
    </div>
  );
};

export default Index;
